<template>
  <div
    ref="containerRef"
    class="mm-filter"
  >
    <div
      v-if="filter?.name && filter.type !== EFilterTypes.Bool && !hideName"
      class="mm-filter__title"
      :class="{
        pointer: collapsible,
        [titleClass]: titleClass
      }"
      @click="collapsible && onClickTitle()"
    >
      <SvgIcon
        v-if="collapsible"
        class="mm-filter__title-icon"
        :class="{ active: isOpen }"
        src="navigation/arrow-top-20px"
      />
      <span>{{ filter?.name }}</span>
    </div>

    <transition name="mm-filter-slide">
      <component
        :is="currentFilter"
        v-if="!isOpen"
        :model-value="internalFilter.filterState"
        :filter="internalFilter.filter"
        :class="{
          'mt-16': filter.type !== EFilterTypes.Bool,
          [filterClass]: filterClass,
        }"
        :is-chosen-all="isChosenAll"
        v-bind="baseFilterProps"
        :disabled="disabled"
        @update:model-value="onUpdateValue"
        @enter="onPressEnter"
      />
    </transition>

    <LoaderButton
      v-if="showButton && props.isFilterAvailable"
      :loader="submitLoader"
      :custom-disabler="internalFilter?.filterState?.IsInvalid || disabled"
      button-text="Применить"
      :icon-src="EIconPath.IndicatorsProgressLightSvg"
      :btn-classes="['btn-primary-with-save-background', 'mm-font-500']"
      :style="filter.submitButtonStyle"
      @confirm="onUpdateClick"
    />

    <MTooltip
      v-else-if="showButton && !props.isFilterAvailable"
      :text="tooltipText"
      :max-width="325"
      is-show-arrow
      :offset="[0, 18]"
    >
      <MButton
        class="mm-filter__disabled-btn"
        :size="MM.EButtonSizes.Small"
        disabled
        full-width
      >
        Применить
      </MButton>
    </MTooltip>
  </div>
</template>

<script lang="ts" setup>
import { IFilter, IFilterItem, IBaseFilterProps } from '../models/filters.model';
import { EFilterTypes } from '../enums/filters.enum';
import SvgIcon from './SvgIcon.vue';
import LoaderButton from './LoaderButton.vue';
import Loader from '../utils/loaderHelper.util';
import { EIconPath } from '../enums/iconPath.enum';
import MSortFilter from './filters/MSortFilter.vue';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';
import { cloneDeep, isEqual } from 'lodash-es';
import { useUserStore } from 'store/user.store';
import { MM } from 'types';

import RangeFilter from './filters/RangeFilter.vue';
import TextFilter from './filters/TextFilter.vue';
import BoolFilter from './filters/BoolFilter.vue';
import EnumFilter from './filters/EnumFilter.vue';
import SortFilter from './filters/SortFilter.vue';
import DateRangeFilter from './filters/DateRangeFilter.vue';
import RadioFilter from './filters/RadioFilter.vue';

const props = withDefaults(defineProps<{
  // Объект, представляющий фильтр
  filter: IFilter;
  // Значение фильтра
  modelValue: IFilter;
  // Флаг, определяющий, должна ли отображаться кнопка Применить
  showButton?: boolean;
  // Является ли фильтр сворачиваемым
  collapsible?: boolean;
  // Должен ли быть фильтр открытым по умолчанию
  defaultOpen?: boolean;
  // Класс, применяемый к заголовку
  titleClass?: string;
  // Должно ли скрываться имя фильтра
  hideName?: boolean;
  // Класс, применяемый к блоку фильтра
  filterClass?: string;
  // Дополнительные пропсы, передаваемые в дочерний компонент, в зависимости от типа фильтра
  baseFilterProps?: IBaseFilterProps;
  // Выбраны ли все значения
  isChosenAll?: boolean;
  // Является ли фильтр выпадающим
  isDropdown?: boolean;
  // Отключен ли фильтр
  disabled?: boolean;
  // Лоадер, появляющийся при сабмите
  submitLoader?: Loader;
  /** Доступен ли фильтр для сабмита */
  isFilterAvailable?: boolean;
}>(), {
  filterClass: '',
  titleClass: '',
});

const emits = defineEmits<{
  (e: 'update:modelValue', newValue: IFilter): void;
  (e: 'submit', newValue: IFilter): void;
}>();

const filtersMap = new Map<EFilterTypes, unknown>([
  [EFilterTypes.Enum, EnumFilter],
  [EFilterTypes.Bool, BoolFilter],
  [EFilterTypes.Range, RangeFilter],
  [EFilterTypes.Text, TextFilter],
  [EFilterTypes.Sort, SortFilter],
  [EFilterTypes.MSort, MSortFilter],
  [EFilterTypes.DateRange, DateRangeFilter],
  [EFilterTypes.Radio, RadioFilter],
]);

const userStore = useUserStore();

const watchSubscription = new WatchSubscription();
const isOpen = ref(props.defaultOpen);
const containerRef = ref<HTMLDivElement>();

const currentFilter = computed(() => filtersMap.get(props.filter?.type));

const internalFilter = ref<IFilterItem>({
  filter: props.filter,
  filterState: cloneDeep(props.modelValue),
});

const tooltipText = computed(() => !userStore.isUserLoggedIn ? 'Доступно только зарегистрированным пользователям' : '');

function onUpdateClick(): void {
  emits('update:modelValue', cloneDeep(internalFilter.value.filterState));

  if (props.showButton) {
    emits('submit', internalFilter.value.filterState);
  }
}

function onUpdateValue(newFilter: IFilter) {
  if (props.showButton) {
    internalFilter.value.filterState = newFilter;
    return;
  }

  emits('update:modelValue', newFilter);
}

function onClickTitle(): void {
  isOpen.value = !isOpen.value;
}

function onPressEnter(): void {
  if (!props.isDropdown) {
    return;
  }
  onUpdateClick();
}

function onSetInternalFilterState(newFilterState: IFilter): void {
  if (!newFilterState) {
    return;
  }

  if (isEqual(newFilterState, internalFilter.value.filterState)) {
    return;
  }

  internalFilter.value.filterState = cloneDeep(newFilterState);
}

watchSubscription.add(
  watch(
    () => props.defaultOpen,
    (newDefaultOpen) => isOpen.value = newDefaultOpen,
  ),

  watch(
    () => props.filter,
    (newFilter) => internalFilter.value.filter = newFilter,
  ),

  watch(
    () => props.modelValue,
    onSetInternalFilterState,
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

defineExpose({
  containerRef,
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-filter {
  &__title {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $filter-title-color;
    }

    &-icon {
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      margin-left: -25px;

      &.active {
        transform: rotate(180deg);
      }
    }

    &.pointer {
      cursor: pointer;
    }
  }

  .btn {
    width: 325px;
    margin-top: 24px;
    justify-content: center;
  }

  &-slide {
    &-enter-from,
    &-leave-to {
      max-height: 0;
      overflow: hidden;
    }

    &-enter-active,
    &-leave-active {
      transition: max-height 0.2s ease-in;
    }

    &-enter-to,
    &-leave-from {
      max-height: 100%;
      overflow: hidden;
    }
  }

  &__disabled-btn {
    margin-top: 24px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  :deep(.mm-sort-filter) {
    padding: 0;
    max-width: 100%;
  }
}
</style>
